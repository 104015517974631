var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    _vm._b(
      {
        attrs: {
          "form-action-method": _vm.formActionWindow,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          "page-path": _vm.pagePath,
          title: _vm.title,
          "title-icon": "mdi-file-document",
          "initial-width": "4",
        },
      },
      "asyent-window",
      _vm.$attrs,
      false
    ),
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.rop !== "new"
                          ? _c("asyent-form-info", {
                              attrs: { "no-title": "", "no-maximize": "" },
                              model: {
                                value: _vm.formData,
                                callback: function ($$v) {
                                  _vm.formData = $$v
                                },
                                expression: "formData",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: _vm.formDataF["id"],
                                                label: "Declaration ID",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF["originatorId"],
                                                  _vm.formDataF[
                                                    "originatorName"
                                                  ],
                                                ],
                                                label: "Originator",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF["providerName"],
                                                label: "Provider",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF[
                                                    "providerObjectId"
                                                  ],
                                                label:
                                                  "Provider Declaration ID",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _vm.viewMode
                                              ? _c("asyent-form-display", {
                                                  attrs: {
                                                    value: [
                                                      _vm.formDataF[
                                                        "customsOfficeCode"
                                                      ],
                                                      _vm.formDataF[
                                                        "customsOfficeName"
                                                      ],
                                                    ],
                                                    label: "Customs Office",
                                                  },
                                                })
                                              : _c("asyent-form-catalog", {
                                                  attrs: {
                                                    sortable: "",
                                                    "view-mode": _vm.viewMode,
                                                    "form-errors":
                                                      _vm.formErrors,
                                                    "form-rules":
                                                      _vm.formFieldsRules,
                                                    "is-disabled-func":
                                                      _vm.isDisabled,
                                                    "service-name": "catalog",
                                                    catalog:
                                                      "CatalogCustomsOffice",
                                                    "show-item-code": "",
                                                    label:
                                                      _vm.ae$lng("Office Code"),
                                                    "item-value": "code",
                                                    "item-text": "name",
                                                    name: "officeCode",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formDataF[
                                                        "customsOfficeCode"
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formDataF,
                                                        "customsOfficeCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formDataF['customsOfficeCode']",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF[
                                                    "declarationType"
                                                  ],
                                                label: "Declaration Type",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF[
                                                    "masterReferenceNumber"
                                                  ],
                                                label:
                                                  "Master Reference Number",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "text-color":
                                                    _vm.formDataF[
                                                      "documentStatus"
                                                    ] === "CREATED"
                                                      ? "black"
                                                      : _vm.formDataF[
                                                          "documentStatus"
                                                        ] ===
                                                        "MANIFEST SUBMITTED"
                                                      ? "black"
                                                      : "white",
                                                  color:
                                                    _vm.formDataF[
                                                      "documentStatus"
                                                    ] === "CREATED"
                                                      ? "#DEC570"
                                                      : _vm.formDataF[
                                                          "documentStatus"
                                                        ] ===
                                                        "MANIFEST SUBMITTED"
                                                      ? "#60d394"
                                                      : "#06a77d",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.ae$EnumToString(
                                                        _vm.formDataF[
                                                          "documentStatus"
                                                        ]
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "6",
                                              md: "6",
                                              lg: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Voyage",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": "",
                                                  "no-maximize": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                  lg: "6",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            [
                                                                              _vm
                                                                                .formDataF[
                                                                                "idPortCall"
                                                                              ][
                                                                                "idCargoVoyage"
                                                                              ][
                                                                                "departurePort"
                                                                              ][
                                                                                "code"
                                                                              ],
                                                                              _vm
                                                                                .formDataF[
                                                                                "idPortCall"
                                                                              ][
                                                                                "idCargoVoyage"
                                                                              ][
                                                                                "departurePort"
                                                                              ][
                                                                                "name"
                                                                              ],
                                                                              _vm
                                                                                .formDataF[
                                                                                "idPortCall"
                                                                              ][
                                                                                "idCargoVoyage"
                                                                              ][
                                                                                "departurePort"
                                                                              ][
                                                                                "countryName"
                                                                              ],
                                                                            ],
                                                                          label:
                                                                            "Departure Port",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-finder",
                                                                      {
                                                                        ref: _vm.name,
                                                                        attrs: {
                                                                          "working-date":
                                                                            _vm.workingDate,
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          "service-name":
                                                                            "catalog",
                                                                          catalog:
                                                                            "CatalogPlaceOfLoading",
                                                                          "data-fields":
                                                                            _vm.dataFields,
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "Departure Port"
                                                                            ),
                                                                          "item-value":
                                                                            "code",
                                                                          name: "departurePort",
                                                                          "as-text":
                                                                            "",
                                                                          "is-not-cacheable": true,
                                                                          "pre-poplated": false,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "idPortCall"
                                                                            ][
                                                                              "idCargoVoyage"
                                                                            ][
                                                                              "departurePort"
                                                                            ][
                                                                              "code"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDataF[
                                                                                  "idPortCall"
                                                                                ][
                                                                                  "idCargoVoyage"
                                                                                ][
                                                                                  "departurePort"
                                                                                ],
                                                                                "code",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                                  formDataF['idPortCall']['idCargoVoyage']['departurePort'][\n                                    'code'\n                                  ]\n                                ",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                  lg: "6",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            [
                                                                              _vm
                                                                                .formDataF[
                                                                                "destinationPlace"
                                                                              ][
                                                                                "code"
                                                                              ],
                                                                              _vm
                                                                                .formDataF[
                                                                                "destinationPlace"
                                                                              ][
                                                                                "name"
                                                                              ],
                                                                              _vm
                                                                                .formDataF[
                                                                                "destinationPlace"
                                                                              ][
                                                                                "countryName"
                                                                              ],
                                                                            ],
                                                                          label:
                                                                            "Destination Place",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-finder",
                                                                      {
                                                                        ref: _vm.name,
                                                                        attrs: {
                                                                          "working-date":
                                                                            _vm.workingDate,
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          "service-name":
                                                                            "catalog",
                                                                          catalog:
                                                                            "CatalogPlaceOfLoading",
                                                                          "data-fields":
                                                                            _vm.dataFields,
                                                                          props:
                                                                            {},
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "Destination Place"
                                                                            ),
                                                                          "item-value":
                                                                            "code",
                                                                          name: "destinationPlace",
                                                                          "as-text":
                                                                            "",
                                                                          "is-not-cacheable": true,
                                                                          "pre-poplated": false,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "destinationPlace"
                                                                            ][
                                                                              "code"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDataF[
                                                                                  "destinationPlace"
                                                                                ],
                                                                                "code",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "formDataF['destinationPlace']['code']",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                  lg: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-text",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "Voyage Number"
                                                                        ),
                                                                      name: "voyageNumber",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "idPortCall"
                                                                        ][
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "voyageNumber"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "idPortCall"
                                                                            ][
                                                                              "idCargoVoyage"
                                                                            ],
                                                                            "voyageNumber",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['idPortCall']['idCargoVoyage']['voyageNumber']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                  lg: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "Departure Date"
                                                                        ),
                                                                      name: "departureDate",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "idPortCall"
                                                                        ][
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departureDate"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "idPortCall"
                                                                            ][
                                                                              "idCargoVoyage"
                                                                            ],
                                                                            "departureDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "\n                                  formDataF['idPortCall']['idCargoVoyage']['departureDate']\n                                ",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "6",
                                              md: "6",
                                              lg: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Date & Times",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": "",
                                                  "no-maximize": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                  lg: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "estimatedTimeOfDeparture"
                                                                        ),
                                                                      type: "datetime-local",
                                                                      name: "estimatedTimeOfDeparture",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "estimatedTimeOfDeparture"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.formDataF,
                                                                            "estimatedTimeOfDeparture",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['estimatedTimeOfDeparture']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                  lg: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "actualTimeOfDeparture"
                                                                        ),
                                                                      type: "datetime-local",
                                                                      name: "actualTimeOfDeparture",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "actualTimeOfDeparture"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.formDataF,
                                                                            "actualTimeOfDeparture",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['actualTimeOfDeparture']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                  lg: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "estimatedTimeOfArrival"
                                                                        ),
                                                                      type: "datetime-local",
                                                                      name: "estimatedTimeOfArrival",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "estimatedTimeOfArrival"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.formDataF,
                                                                            "estimatedTimeOfArrival",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['estimatedTimeOfArrival']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                  lg: "6",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "actualTimeOfArrival"
                                                                        ),
                                                                      type: "datetime-local",
                                                                      name: "actualTimeOfArrival",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "actualTimeOfArrival"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.formDataF,
                                                                            "actualTimeOfArrival",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['actualTimeOfArrival']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "4",
                                                      lg: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "asyent-card",
                                                      {
                                                        attrs: {
                                                          title: "Carrier",
                                                          flat: "",
                                                          "border-color": 2,
                                                          "with-border": "",
                                                          "no-maximize": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "asyent-form-text",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "form-errors":
                                                                                  _vm.formErrors,
                                                                                "form-rules":
                                                                                  _vm.formFieldsRules,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "SCAC Id."
                                                                                  ),
                                                                                name: "scacId",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formDataF[
                                                                                    "carrier"
                                                                                  ][
                                                                                    "id"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formDataF[
                                                                                        "carrier"
                                                                                      ],
                                                                                      "id",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "formDataF['carrier']['id']",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "asyent-form-text",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "form-errors":
                                                                                  _vm.formErrors,
                                                                                "form-rules":
                                                                                  _vm.formFieldsRules,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "Name & Address"
                                                                                  ),
                                                                                name: "carrierNameAddress",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formDataF[
                                                                                    "carrier"
                                                                                  ][
                                                                                    "name"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formDataF[
                                                                                        "carrier"
                                                                                      ],
                                                                                      "name",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "formDataF['carrier']['name']",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "4",
                                                      lg: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "asyent-card",
                                                      {
                                                        attrs: {
                                                          title:
                                                            "Shipping Agent",
                                                          flat: "",
                                                          "border-color": 2,
                                                          "with-border": "",
                                                          "no-maximize": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                          lg: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "asyent-form-text",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "form-errors":
                                                                                  _vm.formErrors,
                                                                                "form-rules":
                                                                                  _vm.formFieldsRules,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "Eori No."
                                                                                  ),
                                                                                name: "shippingAgentId",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formDataF[
                                                                                    "shippingAgent"
                                                                                  ][
                                                                                    "id"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formDataF[
                                                                                        "shippingAgent"
                                                                                      ],
                                                                                      "id",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "formDataF['shippingAgent']['id']",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                          lg: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "asyent-form-text",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "form-errors":
                                                                                  _vm.formErrors,
                                                                                "form-rules":
                                                                                  _vm.formFieldsRules,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "Provider Ref."
                                                                                  ),
                                                                                name: "shippingAgentProviderRef",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formDataF[
                                                                                    "shippingAgent"
                                                                                  ][
                                                                                    "providerRef"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formDataF[
                                                                                        "shippingAgent"
                                                                                      ],
                                                                                      "providerRef",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "formDataF['shippingAgent']['providerRef']",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "asyent-form-text",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "form-errors":
                                                                                  _vm.formErrors,
                                                                                "form-rules":
                                                                                  _vm.formFieldsRules,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "Name & Address"
                                                                                  ),
                                                                                name: "shippingAgentNameAddress",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formDataF[
                                                                                    "shippingAgent"
                                                                                  ][
                                                                                    "name"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formDataF[
                                                                                        "shippingAgent"
                                                                                      ],
                                                                                      "name",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "formDataF['shippingAgent']['name']",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "4",
                                                      lg: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "asyent-card",
                                                      {
                                                        attrs: {
                                                          title:
                                                            "Transport Means",
                                                          flat: "",
                                                          "border-color": 2,
                                                          "with-border": "",
                                                          "no-maximize": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                          lg: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm.viewMode
                                                                          ? _c(
                                                                              "asyent-form-display",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      [
                                                                                        _vm
                                                                                          .formDataF[
                                                                                          "transportMeans"
                                                                                        ][
                                                                                          "idNumber"
                                                                                        ],
                                                                                        _vm
                                                                                          .formDataF[
                                                                                          "transportMeans"
                                                                                        ][
                                                                                          "name"
                                                                                        ],
                                                                                      ],
                                                                                    label:
                                                                                      "transportMeansId",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "asyent-form-text",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "view-mode":
                                                                                      _vm.viewMode,
                                                                                    "form-errors":
                                                                                      _vm.formErrors,
                                                                                    "form-rules":
                                                                                      _vm.formFieldsRules,
                                                                                    "is-disabled-func":
                                                                                      _vm.isDisabled,
                                                                                    label:
                                                                                      _vm.ae$lng(
                                                                                        "ID"
                                                                                      ),
                                                                                    name: "transportMeansId",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .formDataF[
                                                                                        "transportMeans"
                                                                                      ][
                                                                                        "idNumber"
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .formDataF[
                                                                                            "transportMeans"
                                                                                          ],
                                                                                          "idNumber",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "formDataF['transportMeans']['idNumber']",
                                                                                  },
                                                                              }
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                          lg: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm.viewMode
                                                                          ? _c(
                                                                              "asyent-form-display",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      [
                                                                                        _vm
                                                                                          .formDataF[
                                                                                          "transportMeans"
                                                                                        ][
                                                                                          "transportModeCode"
                                                                                        ],
                                                                                        _vm
                                                                                          .formDataF[
                                                                                          "transportMeans"
                                                                                        ][
                                                                                          "transportModeName"
                                                                                        ],
                                                                                      ],
                                                                                    label:
                                                                                      "Transport Mode",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "asyent-form-text",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "view-mode":
                                                                                      _vm.viewMode,
                                                                                    "form-errors":
                                                                                      _vm.formErrors,
                                                                                    "form-rules":
                                                                                      _vm.formFieldsRules,
                                                                                    "is-disabled-func":
                                                                                      _vm.isDisabled,
                                                                                    label:
                                                                                      _vm.ae$lng(
                                                                                        "transportMode"
                                                                                      ),
                                                                                    name: "transportMode",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .formDataF[
                                                                                        "transportMeans"
                                                                                      ][
                                                                                        "transportModeCode"
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .formDataF[
                                                                                            "transportMeans"
                                                                                          ],
                                                                                          "transportModeCode",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "formDataF['transportMeans']['transportModeCode']",
                                                                                  },
                                                                              }
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                          lg: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm.viewMode
                                                                          ? _c(
                                                                              "asyent-form-display",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      [
                                                                                        _vm
                                                                                          .formDataF[
                                                                                          "transportMeans"
                                                                                        ][
                                                                                          "nationalityCode"
                                                                                        ],
                                                                                        _vm
                                                                                          .formDataF[
                                                                                          "transportMeans"
                                                                                        ][
                                                                                          "nationalityName"
                                                                                        ],
                                                                                      ],
                                                                                    label:
                                                                                      "Nationality",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "asyent-form-text",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    "view-mode":
                                                                                      _vm.viewMode,
                                                                                    "form-errors":
                                                                                      _vm.formErrors,
                                                                                    "form-rules":
                                                                                      _vm.formFieldsRules,
                                                                                    "is-disabled-func":
                                                                                      _vm.isDisabled,
                                                                                    label:
                                                                                      _vm.ae$lng(
                                                                                        "Nationality"
                                                                                      ),
                                                                                    name: "nationality",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .formDataF[
                                                                                        "transportMeans"
                                                                                      ][
                                                                                        "nationalityCode"
                                                                                      ],
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .formDataF[
                                                                                            "transportMeans"
                                                                                          ],
                                                                                          "nationalityCode",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "formDataF['transportMeans']['nationalityCode']",
                                                                                  },
                                                                              }
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "4",
                                                      lg: "3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "asyent-card",
                                                      {
                                                        attrs: {
                                                          title: "Totals",
                                                          flat: "",
                                                          "border-color": 2,
                                                          "with-border": "",
                                                          "no-maximize": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                          lg: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "asyent-form-text",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "form-errors":
                                                                                  _vm.formErrors,
                                                                                "form-rules":
                                                                                  _vm.formFieldsRules,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                props:
                                                                                  _vm.props,
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "consignments"
                                                                                  ),
                                                                                name: "totalConsignments",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formDataF[
                                                                                    "totalConsignments"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.formDataF,
                                                                                      "totalConsignments",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "formDataF['totalConsignments']",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                          lg: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "asyent-form-text",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "form-errors":
                                                                                  _vm.formErrors,
                                                                                "form-rules":
                                                                                  _vm.formFieldsRules,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "packages"
                                                                                  ),
                                                                                name: "totalPackages",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formDataF[
                                                                                    "totalPackages"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.formDataF,
                                                                                      "totalPackages",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "formDataF['totalPackages']",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                          lg: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "asyent-form-text",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "form-errors":
                                                                                  _vm.formErrors,
                                                                                "form-rules":
                                                                                  _vm.formFieldsRules,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "equipments"
                                                                                  ),
                                                                                name: "totalEquipments",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formDataF[
                                                                                    "totalEquipments"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.formDataF,
                                                                                      "totalEquipments",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "formDataF['totalEquipments']",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          md: "6",
                                                                          lg: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "asyent-form-text",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "form-errors":
                                                                                  _vm.formErrors,
                                                                                "form-rules":
                                                                                  _vm.formFieldsRules,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "grossWeight"
                                                                                  ),
                                                                                name: "totalGrossWeight",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formDataF[
                                                                                    "totalGrossWeight"
                                                                                  ],
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.formDataF,
                                                                                      "totalGrossWeight",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "formDataF['totalGrossWeight']",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                    lg: "12",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.rop !== "new"
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "TreeView",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      widget: "",
                                      "with-border": "",
                                      meta: {
                                        voyageNumber:
                                          _vm.formDataF["idPortCall"][
                                            "idCargoVoyage"
                                          ]["voyageNumber"],
                                        departurePort:
                                          _vm.formDataF["idPortCall"][
                                            "idCargoVoyage"
                                          ]["departurePort"]["code"],
                                        departureDate:
                                          _vm.formDataF["idPortCall"][
                                            "idCargoVoyage"
                                          ]["departureDate"],
                                      },
                                      title: _vm.ae$lng("Related Consignments"),
                                      mandatory: "",
                                      name: "flagState",
                                      sortable: "",
                                      "show-item-avatar": "",
                                      "form-errors": _vm.formErrors,
                                      "form-rules": _vm.formFieldsRules,
                                      "is-disabled-func": _vm.isDisabled,
                                      "items-reload":
                                        _vm.relatedConsignmentReload,
                                      "document-id": _vm.id,
                                      loading: _vm.dataLoading,
                                      "drop-down": "",
                                      "item-text": "ctyDsc",
                                      "item-value": "ctyCod",
                                      label: "Flag State",
                                    },
                                    on: {
                                      "toolbar-action": function ($event) {
                                        return _vm.toolbarMethod($event)
                                      },
                                    },
                                  },
                                  "TreeView",
                                  _vm.$attrs,
                                  false
                                ),
                                _vm.$listeners
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.rop !== "new"
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: {
                                  extended: "",
                                  expandable: "",
                                  title: "Additional Information",
                                },
                              },
                              [
                                _c("v-data-table", {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    headers: _vm.addInfoTableHeaders,
                                    items:
                                      _vm.formDataF["additionalInformation"],
                                    "items-per-page": -1,
                                    "hide-default-footer": "",
                                    "multi-sort": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.rop !== "new"
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: {
                                  extended: "",
                                  expandable: "",
                                  title: "Documents",
                                },
                              },
                              [
                                _c("v-data-table", {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    headers: _vm.documentTableHeaders,
                                    items: _vm.formDataF["documents"],
                                    "items-per-page": -1,
                                    "hide-default-footer": "",
                                    "multi-sort": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item.documentId",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-display-div",
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: _vm.showDocumentLink(
                                                        item["documentId"]
                                                      ),
                                                      tag: "a",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          "text-decoration":
                                                            "unset",
                                                        },
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v("mdi-file-link")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "form-display-text form-display-link",
                                                        staticStyle: {
                                                          color: "unset",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item["documentId"]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4259904704
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.rop !== "new"
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: {
                                  extended: "",
                                  expandable: "",
                                  title: "Events",
                                },
                              },
                              [
                                _c("v-data-table", {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    headers: _vm.eventTableHeaders,
                                    items: _vm.formDataF["events"],
                                    "items-per-page": -1,
                                    "hide-default-footer": "",
                                    "multi-sort": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item.eventId",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-display-div",
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: _vm.showEventLink(
                                                        item["eventId"]
                                                      ),
                                                      tag: "a",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          "text-decoration":
                                                            "unset",
                                                        },
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v("mdi-file-link")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "form-display-text form-display-link",
                                                        staticStyle: {
                                                          color: "unset",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item["eventId"]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2514804064
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("asyent-toc"),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "asyent-card",
        _vm._g(
          _vm._b(
            {
              attrs: { title: _vm.title, extended: "" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item",
                        { staticClass: "pl-0" },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _vm.title && _vm.title.length > 0
                                ? _c("v-list-item-title", {
                                    staticClass:
                                      "title font-weight-medium text-body-1 text-truncate",
                                    domProps: {
                                      textContent: _vm._s(_vm.title),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "toolbar-actions",
                  fn: function () {
                    return [
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        { attrs: { bottom: "" } },
                        [
                          [
                            _c("asyent-button", {
                              attrs: {
                                icon: _vm.$vuetify.breakpoint.smAndDown,
                                text: !_vm.$vuetify.breakpoint.smAndDown,
                                "button-icon": "mdi-reload",
                                "button-tooltip": _vm.ae$lng(
                                  "forms.select.reloadList"
                                ),
                              },
                              on: { confirmed: _vm.reloadItemsFromParent },
                            }),
                          ],
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            "asyent-card",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.dataLoading || _vm.dataError
            ? [
                _vm._t("formDataLoading", function () {
                  return [
                    _c(
                      "v-sheet",
                      {
                        attrs: {
                          color:
                            "grey " +
                            (_vm.$vuetify.theme.dark
                              ? "darken-2"
                              : "lighten-4"),
                        },
                      },
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "mx-auto",
                          attrs: { elevation: "0", type: "card-avatar" },
                        }),
                        _c("v-skeleton-loader", {
                          staticClass: "mx-auto",
                          attrs: { elevation: "0", type: "actions" },
                        }),
                        _c(
                          "v-row",
                          { attrs: { justify: "center" } },
                          [
                            _vm.dataError
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "text-center text-truncate text-no-wrap",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ae$lng("forms.unableToLoadMessage")
                                      )
                                    ),
                                  ]
                                )
                              : _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "text-center text-truncate text-no-wrap",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ae$lng("forms.loadingWaitMessage")
                                      )
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ]
            : [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0 ma-0" },
                  [
                    _vm._t("default"),
                    !_vm.viewMode
                      ? _c("ValidationProvider", {
                          attrs: { name: _vm.label, rules: _vm.formFieldRules },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "pa-4",
                                        attrs: { justify: "space-between" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "5" } },
                                          [
                                            _c(
                                              "v-treeview",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      items: _vm.items,
                                                      active: _vm.active,
                                                      open: _vm.open,
                                                      "load-children":
                                                        _vm.fetchUsers,
                                                      "loading-icon":
                                                        "mdi-file-document",
                                                      activatable: "",
                                                      color: "warning",
                                                      "return-object": "",
                                                      transition: "",
                                                      "aria-label": _vm.label,
                                                      disabled: _vm.isDisabled,
                                                      "error-messages":
                                                        _vm.formFieldErrors(
                                                          errors
                                                        ),
                                                      loading: _vm.dataLoading,
                                                      "small-chips":
                                                        _vm.multiple,
                                                      "auto-select-first": "",
                                                      "cache-items": "",
                                                      clearable: "",
                                                      "deletable-chips": "",
                                                      placeholder: _vm.ae$lng(
                                                        "forms.select.pleaseSelect"
                                                      ),
                                                      label: "",
                                                      "item-text":
                                                        "consignment.id",
                                                      "item-key": "nodeId",
                                                      "item-children":
                                                        "consignmentTreeNode",
                                                      dense:
                                                        _vm.appConfig.forms
                                                          .dense,
                                                      flat: _vm.appConfig.forms
                                                        .flat,
                                                      solo: _vm.appConfig.forms
                                                        .solo,
                                                      "solo-inverted":
                                                        _vm.appConfig.forms
                                                          .soloInverted,
                                                      filled:
                                                        _vm.appConfig.forms
                                                          .filled,
                                                      outlined:
                                                        _vm.appConfig.forms
                                                          .outlined,
                                                      "single-line":
                                                        !_vm.multiple,
                                                    },
                                                    on: {
                                                      "update:active":
                                                        function ($event) {
                                                          _vm.active = $event
                                                        },
                                                      "update:open": function (
                                                        $event
                                                      ) {
                                                        _vm.open = $event
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "prepend",
                                                          fn: function (ref) {
                                                            var item = ref.item
                                                            return [
                                                              !item.children
                                                                ? _c("v-icon", [
                                                                    _vm._v(
                                                                      " mdi-file-document "
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  "v-treeview",
                                                  _vm.$attrs,
                                                  false
                                                ),
                                                _vm.$listeners
                                              )
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          attrs: { vertical: "" },
                                        }),
                                        _c("v-col", {}, [
                                          _vm.active.length === 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-h6 grey--text text--lighten-1 font-weight-light",
                                                  staticStyle: {
                                                    "align-self": "center",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Select a Consignment for more details "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "text-left",
                                                      attrs: {
                                                        tag: "v-card-text",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "4",
                                                            md: "4",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.active[0][
                                                                    "consignment"
                                                                  ][
                                                                    "officeCode"
                                                                  ],
                                                                label:
                                                                  "Office Code",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "4",
                                                            md: "4",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.active[0][
                                                                    "consignment"
                                                                  ][
                                                                    "uniqueConsignmentReference"
                                                                  ],
                                                                label:
                                                                  "uniqueConsignmentReference",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "4",
                                                            md: "4",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.active[0][
                                                                    "consignment"
                                                                  ][
                                                                    "previousConsignmentReference"
                                                                  ],
                                                                label:
                                                                  "Previous Consignment Reference",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "4",
                                                            md: "4",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.active[0][
                                                                    "consignment"
                                                                  ][
                                                                    "idCargoVoyage"
                                                                  ][
                                                                    "departureDate"
                                                                  ],
                                                                "as-date": "",
                                                                label:
                                                                  "Departure Date",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "4",
                                                            md: "4",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.active[0][
                                                                    "consignment"
                                                                  ][
                                                                    "idCargoVoyage"
                                                                  ][
                                                                    "voyageNumber"
                                                                  ],
                                                                label:
                                                                  "Voyage Number",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "4",
                                                            md: "4",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.active[0][
                                                                    "consignment"
                                                                  ][
                                                                    "idCargoVoyage"
                                                                  ][
                                                                    "departurePort"
                                                                  ]["code"],
                                                                label:
                                                                  "Voyage Number",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: _vm.executeOnRow(
                                                                  _vm.active[0][
                                                                    "consignment"
                                                                  ]["id"]
                                                                ),
                                                                exact: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " /cargo/consignments/view/" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .active[0][
                                                                      "consignment"
                                                                    ]["id"]
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                  },
                                                                  _vm.on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " mdi-file-find-outline "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3418847613
                          ),
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <!-- Drop-down -->
    <asyent-card :title="title" extended v-bind="$attrs" v-on="$listeners">
      <template v-slot:title>
        <v-list-item class="pl-0">
          <v-list-item-content>
            <!-- <v-list-item-subtitle
                v-if="subTitle && subTitle.length > 0"
                class="font-weight-light caption text-truncate"
                v-text="subTitle"
              ></v-list-item-subtitle> -->
            <v-list-item-title
              v-if="title && title.length > 0"
              class="title font-weight-medium text-body-1 text-truncate"
              v-text="title"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:toolbar-actions>
        <v-spacer />

        <!-- Toolbar actions -->
        <v-tooltip bottom>
          <template>
            <asyent-button
              :icon="$vuetify.breakpoint.smAndDown"
              :text="!$vuetify.breakpoint.smAndDown"
              button-icon="mdi-reload"
              :button-tooltip="ae$lng('forms.select.reloadList')"
              @confirmed="reloadItemsFromParent"
            />
          </template>
          <!-- <span>{{ action.tooltip }}</span> -->
        </v-tooltip>
        <!-- Header actions -->
      </template>
      <template v-if="dataLoading || dataError">
        <slot name="formDataLoading">
          <v-sheet :color="`grey ${$vuetify.theme.dark ? 'darken-2' : 'lighten-4'}`">
            <v-skeleton-loader class="mx-auto" elevation="0" type="card-avatar" />
            <v-skeleton-loader class="mx-auto" elevation="0" type="actions" />
            <v-row justify="center">
              <v-col v-if="dataError" class="text-center text-truncate text-no-wrap">{{
                ae$lng("forms.unableToLoadMessage")
              }}</v-col>
              <v-col v-else class="text-center text-truncate text-no-wrap">{{
                ae$lng("forms.loadingWaitMessage")
              }}</v-col>
            </v-row>
          </v-sheet>
        </slot>
      </template>
      <template v-else>
        <v-card-text class="pa-0 ma-0">
          <slot />
          <ValidationProvider
            v-if="!viewMode"
            v-slot="{ errors }"
            :name="label"
            :rules="formFieldRules"
          >
            <v-row class="pa-4" justify="space-between">
              <v-col cols="5">
                <!--  mdi-file-document        
                   -->
                <v-treeview
                  :items="items"
                  :active.sync="active"
                  :open.sync="open"
                  :load-children="fetchUsers"
                  loading-icon="mdi-file-document"
                  activatable
                  color="warning"
                  return-object
                  transition
                  :aria-label="label"
                  :disabled="isDisabled"
                  :error-messages="formFieldErrors(errors)"
                  :loading="dataLoading"
                  :small-chips="multiple"
                  auto-select-first
                  cache-items
                  class="mt-2"
                  clearable
                  deletable-chips
                  :placeholder="ae$lng('forms.select.pleaseSelect')"
                  label=""
                  item-text="consignment.id"
                  item-key="nodeId"
                  item-children="consignmentTreeNode"
                  :dense="appConfig.forms.dense"
                  :flat="appConfig.forms.flat"
                  :solo="appConfig.forms.solo"
                  :solo-inverted="appConfig.forms.soloInverted"
                  :filled="appConfig.forms.filled"
                  :outlined="appConfig.forms.outlined"
                  v-bind="$attrs"
                  :single-line="!multiple"
                  v-on="$listeners"
                >
                  <template v-slot:prepend="{ item }">
                    <v-icon v-if="!item.children"> mdi-file-document </v-icon>
                  </template></v-treeview
                >
              </v-col>
              <v-divider vertical></v-divider>

              <v-col class="">
                <div
                  v-if="active.length === 0"
                  class="text-h6 grey--text text--lighten-1 font-weight-light"
                  style="align-self: center"
                >
                  Select a Consignment for more details
                </div>
                <div v-else>
                  <v-row class="text-left" tag="v-card-text">
                    <v-col cols="4" md="4">
                      <asyent-form-display
                        :value="active[0]['consignment']['officeCode']"
                        label="Office Code"
                      />
                    </v-col>

                    <v-col cols="4" md="4">
                      <asyent-form-display
                        :value="active[0]['consignment']['uniqueConsignmentReference']"
                        label="uniqueConsignmentReference"
                      />
                    </v-col>

                    <v-col cols="4" md="4">
                      <asyent-form-display
                        :value="active[0]['consignment']['previousConsignmentReference']"
                        label="Previous Consignment Reference"
                      />
                    </v-col>
                    <v-col cols="4" md="4">
                      <asyent-form-display
                        :value="active[0]['consignment']['idCargoVoyage']['departureDate']"
                        as-date
                        label="Departure Date"
                      />
                    </v-col>
                    <v-col cols="4" md="4">
                      <asyent-form-display
                        :value="active[0]['consignment']['idCargoVoyage']['voyageNumber']"
                        label="Voyage Number"
                      />
                    </v-col>

                    <v-col cols="4" md="4">
                      <asyent-form-display
                        :value="active[0]['consignment']['idCargoVoyage']['departurePort']['code']"
                        label="Voyage Number"
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <router-link :to="executeOnRow(active[0]['consignment']['id'])" exact>
                        /cargo/consignments/view/{{ active[0]["consignment"]["id"] }}
                        <v-icon class="mr-2" v-on="on"> mdi-file-find-outline </v-icon>
                      </router-link>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </ValidationProvider>
        </v-card-text></template
      >
    </asyent-card>
  </div>
</template>

<script>
  import { FormPropsMixin } from "@/mixins/_asyent-form-props-mixin.js";

  export default {
    name: "AsyentFormSelect",
    components: {},
    mixins: [FormPropsMixin],
    inheritAttrs: false,
    props: {
      title: {
        type: String,
        default: ""
      },

      value: {
        type: Array,
        default: () => []
      },

      documentId: {
        type: String,
        default: ""
      },

      itemsReload: {
        // eslint-disable-next-line no-unused-vars
        type: function Function(x) {},
        default: () => {}
      },
      itemsReloadParams: {
        type: [Object, String, Array],
        default: ""
      },
      itemsStatic: {
        type: Boolean,
        default: false
      },

      name: {
        type: String,
        required: true
      },
      formRules: {
        type: Object,
        default: () => new Object()
      },
      formErrors: {
        type: Object,
        default: () => new Object()
      },
      isDisabledFunc: {
        // eslint-disable-next-line no-unused-vars
        type: function Function(x) {},
        default: () => {}
      },

      viewMode: {
        type: Boolean,
        default: false
      },

      dataError: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dialog: false,
        dataLoading: false,
        items: [],
        active: [],
        open: [],

        headerActions: [
          {
            name: "add",
            tooltip: "New",
            icon: "mdi-plus",
            type: "slink",
            link: "/admin/queries/new",
            roles: ["connectid-unit-create"]
          }
        ]
      };
    },
    computed: {
      selected() {
        if (!this.active.length) return undefined;

        const id = this.active[0];

        return this.users.find((user) => user.id === id);
      },
      formFieldRules() {
        const rules = this.formRules[this.name];
        if (rules) {
          return rules;
        }
        return "";
      },

      isDisabled() {
        return this.disabled || this.isDisabledFunc(this.name) || this.readonly;
      }
    },
    watch: {
      itemsReloadParams: {
        handler() {
          if (this.itemsReload) {
            this.reloadItemsFromParent();
          }
        }
      }
    },
    mounted() {
      if (this.itemsReload) {
        this.reloadItemsFromParent();
      }
    },
    methods: {
      async fetchUsers(item) {
        console.log(item.consignment.uniqueConsignmentReference);
        console.log(this.id);

        var currentNode;
        const requestBody = {
          documentId: this.documentId,
          reference: item.consignment.uniqueConsignmentReference
        };
        // const callback(err, result)={}

        const responseCallback = (err, result) => {
          if (result) {
            if (result.consignmentTreeNode.length != 0) {
              item.consignmentTreeNode.push(...result.consignmentTreeNode);
            } else {
              delete item["consignmentTreeNode"];
            }
            currentNode = item;
          } else {
            console.log(err);
          }
        };

        const doneCallback = () => {
          this.open.push(currentNode);
        };
        this.$httpAPI.POST(
          "cargo",
          `/v1/declarations/declaration-tree`,
          requestBody,
          responseCallback,
          doneCallback.bind(this)
        );
      },

      executeOnRow(item) {
        return "/cargo/consignments/view/" + item;
      },

      reloadItemsFromParent() {
        if (!this.itemsStatic) {
          this.itemsReload(this.itemsReloadParams, (err, data) => {
            this.dataLoading = true;
            // setTimeout(() => {
            if (data) {
              this.dataLoading = false;
              this.items = [];
              this.items.splice(0);
              this.items.push(...data["consignmentTreeNode"]);
              console.log(this.items);
            } else {
              this.dataLoading = false;
              this.items.splice(0);
            }
            // }, 2000);
          });
        } else {
          this.dataLoading = false;
          this.items.splice(0);
          this.items.push(...this.itemsReload());
        }
      },
      formFieldErrors(errors) {
        const errorsFromServer = this.formErrors[this.name];
        const errorsFromClient = errors;
        const hasErrorsFromServer =
          errorsFromServer && Array.isArray(errorsFromServer) && errorsFromServer.length > 0;
        const hasErrorsFromClient =
          errorsFromClient && Array.isArray(errorsFromClient) && errorsFromClient.length > 0;
        if (hasErrorsFromServer && hasErrorsFromClient) {
          return [...errorsFromServer, ...errorsFromClient];
        } else if (hasErrorsFromServer) {
          return [...errorsFromServer];
        } else if (hasErrorsFromClient) {
          return [...errorsFromClient];
        } else {
          return [];
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~@/scss";
  @import "~@/scss/_asyent-components.scss";

  .chip-size {
    min-width: $asyent-form-select-chip-width !important;
    max-width: $asyent-form-select-chip-width !important;
    width: $asyent-form-select-chip-width !important;
  }
</style>

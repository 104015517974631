var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "header-actions": _vm.headerActions,
        "page-path": _vm.ae$pagePath,
        title: _vm.ae$title,
        "toolbar-actions": _vm.toolbarActions,
        widget: _vm.widget,
        "title-icon": "mdi-file-document",
        "initial-width": "4",
      },
      on: {
        "toolbar-action": function ($event) {
          return _vm.toolbarMethod($event)
        },
      },
    },
    [
      _c("asyent-search", {
        attrs: {
          "filter-dialog": _vm.filterDialog,
          filters: _vm.filters,
          headers: _vm.headers,
          "reload-counter": _vm.reloadCounter,
          "row-actions": _vm.rowActions,
          "row-method": _vm.rowMethod,
          "search-method": _vm.search,
        },
        on: {
          "filter-dialog": function ($event) {
            _vm.filterDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.documentStatus",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      "text-color":
                        item["documentStatus"] === "CREATED"
                          ? "black"
                          : item["documentStatus"] === "MANIFEST SUBMITTED"
                          ? "black"
                          : "white",
                      color:
                        item["documentStatus"] === "CREATED"
                          ? "#DEC570"
                          : item["documentStatus"] === "MANIFEST SUBMITTED"
                          ? "#60d394"
                          : "#06a77d",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.ae$EnumToString(item["documentStatus"])) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "bottom-space" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }